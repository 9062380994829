<template>
  <div
    id="app"
    :dark="$theme.options.dark"
  >
    <!--Aqui irá o sitemap-->

    <v-bind :is="layout">
      <template v-slot:toolbar-avatar>
        <v-avatar style="cursor: pointer" max-height="60px" size="85" class="ml-3" tile data-cy="toolbar-avatar">
          <v-img :src="logo" width="100%" height="100%" contain data-cy="toolbar-img"/>
        </v-avatar>
      </template>

      <template #toolbar-user-options>
        <ProfileMenu />
        <v-list-item-title data-cy="toolbar-list-item-logout-title" />
      </template>

      <template #toolbar-title>
        <div/>
      </template>
    </v-bind>

    <notifications
      group="notifications-bottom-right"
      position="bottom right"
      classes="custom-notification"
    />

    <BlockUI
      v-if="loading"
      id="ui-loader-1234"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </BlockUI>
  </div>
</template>

<script>
import 'shared/styles/variables.scss'
// layout em branco da página index
import IndexLayout from '@bmp-vue/core/layouts/IndexLayout'

// layout do sistema
import AppLayout from '@bmp-vue/core/layouts/AppLayout'

import ProfileMenu from '../src/components/ProfileMenu'

export default {
  name: 'App',
  components: {
    AppLayout,
    ProfileMenu
  },
  metaInfo: {
    title: 'Backoffice'
  },
  data () {
    return {
      isLoaded: false,
      signedIn: false,
      logo: '/assets/img/logobmp.svg'
    }
  },
  computed: {
    loading () {
      return this.$store.getters.loading || this.$store.getters.activeRequests > 0
    },
    layout () {
      this.$store.commit('SET_LAYOUT', IndexLayout)
      // verifica se não é a tela de login, se for, mantém o layout em branco

      if (!this.$route.meta.anonymousAuth && ['/'].indexOf(this.$route.path) < 0) {
        this.$store.commit('SET_LAYOUT', AppLayout)
      }

      return this.$store.getters.layout
    }
  },
  mounted () {
    var vm = this

    const customLogo = localStorage.getItem('customLogo');
    if (customLogo) {
      vm.logo = customLogo;
    }

    vm.$store.commit('isMobile')
    if (!vm.$store.getters.isMobile) {
      vm.$store.commit('drawer')
    }

    window.addEventListener('resize', function () {
      vm.$store.commit('isMobile')
    })
  },
  methods: {
    verificar: function () {
      var vm = this
      setTimeout(function () {
        if (!vm.loading && vm.signedIn) {
          vm.isLoaded = true
        } else {
          vm.verificar()
        }
      }, 10)
    },
    logout: function () {
    },
  }
}
</script>