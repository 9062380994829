import axios from 'axios';

const controller = 'PortalCustomizacoesCores'
const url = process.env.VUE_APP_ROOT_API + controller;

const CustomColors = {
    GetCustomTheme: async function (path) {
        var promise =  new Promise((resolve) => {
            let slug = null

            if (window.localStorage.user) {
                slug = JSON.parse(window.localStorage.user).slug || null
            }

            if (path.includes('login')) {
                slug = path.split('/')[2]
            }

            if (slug) {
                axios.get(`${url}/Slug/?Slug=${slug}`)
                    .then(response => { 
                        if (response.data) {
                            resolve({
                                primary: response.data.primaryColor,
                                secondary: response.data.secondaryColor,
                                accent: response.data.accentColor,
                                error: response.data.errorColor,
                                info: response.data.infoColor,
                                success: response.data.successColor,
                                warning: response.data.warningColor,
                            }) 
                        }

                        resolve(null)
                    })
            } else {
                resolve(null)
            }

        });

        return promise
    }
}

export default CustomColors