import api from '../base/ControllerUtil'

const controller = 'Empresa'
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({
  Listar: dto => (
    api.Axios.GetParams(url, dto)
  ),

  ListarNomes: () => (
    api.Axios.GetParams(url + '/Nome')
  ),
  
  ListarByCodigo: dto => (
    api.Axios.Get(url, dto)
  ),

  ListarSimples: dto => (
    api.Axios.GetParams(url + '/Simples', dto)
  ),

  ListarFiltro: dto => (
    api.Axios.GetParams(url + '/Filtro', dto)
  ),

  ListarFiltroLotes: dto => (
    api.Axios.GetParams(url + '/FiltroLotes', dto)
  ),

  ListarByPortal: dto => (
    api.Axios.GetParams(url + '/ConveniadasPortal', dto)
  )
}, api.GetCrud(controller, null), {});
