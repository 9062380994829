import api from '../base/ControllerUtil'

const controller = 'Portal'
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({
  Listar: (dto) => (
    api.Axios.GetParams(url, dto)
  ),
  ListarNomes: () => (
    api.Axios.GetParams(url + '/Nome')
  ),
  ListarSimples: dto => (
    api.Axios.GetParams(url + '/Simples', dto)
  ),
  ListarByCodigo: dto => (
    api.Axios.Get(url, dto)
  ),
  ListarFiltroLotes: dto => (
    api.Axios.GetParams(url + '/FiltroLotes', dto)
  )
}, api.GetCrud(controller, null), {});
