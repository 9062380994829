import Vue from 'vue';
import App from './App.vue';
import core from '@bmp-vue/core';
import DatePicker from '../src/components/Datepicker.vue';
import { SetupUI } from './plugins/api/util';
import CustomImage from './controllers/customImage';
import CustomColors from './controllers/customcolors';
import theme from './plugins/vuetify/theme';
import datadogRumPlugin from './plugins/datadogRum';

(async () => {
  Vue.config.productionTip = false;

  Vue.use(datadogRumPlugin);

  const controllers = require.context('@/controllers', true, /\.js$/);
  const paths = require('@/plugins/router/paths').default;

  if (location.pathname !== '/'){
      const customTheme = await CustomColors.GetCustomTheme(location.pathname);
    
    if (customTheme) {
      theme.themes.dark = { ...theme.themes.dark, ...customTheme };
      theme.themes.light = { ...theme.themes.light, ...customTheme };
      localStorage.setItem('customTheme', JSON.stringify(customTheme));
    }
  }
  
  const customLogo = await CustomImage.GetCustomLogo(location.pathname);

  if (customLogo) {
    const logoElement = document.getElementById('custom-logo');
    if (logoElement) {
      logoElement.src = customLogo;
    }

    const iconElement = document.getElementById('custom-icon');
    if (iconElement) {
      iconElement.href = customLogo;
    }

    theme.logo = customLogo;
  }

  Vue.use(core, {
    controllers,
    paths,
    theme,
    defaultViews: false,
    carregaEnums: false,
    mount: (options) => {
      Vue.component('DatePicker', DatePicker);

      SetupUI(Vue.prototype)

      new Vue({
        ...options,
        render: h => h(App)
      }).$mount('#app');
    }
  })
})()