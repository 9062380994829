export const SetupUI = (vm) => {
    vm.$api.UTIL.Auth.RegisterToken = function (data, userName, extendUser) {
      if (vm.$api.UI.PostLoadData(data)) {
        if (data.accessToken) {
          if (!extendUser) { extendUser = {} }
    
          const user = {
            ...extendUser,
            email: data.email,
            token: data.accessToken,
            refreshToken: data.refreshToken,
            roles: data.roles && data.roles.length > 0 ? data.roles : vm.$api.UTIL.Auth.GetUserRole(data.accessToken),
            expiresAt: new Date(
              new Date().getTime() + data.expires * 1000
              ),
              identificador: userName || data.nomeUsuario || data.identificador
            }
            
            const getPermissoes = () => {
              const claims = JSON.parse(window.atob(data.accessToken.split('.')[1]))
    
              return claims['bmp_consignado_permissoes'].split(',').map(x => "Consignado." + x)
            }
    
            user.roles = [ user.roles, ...getPermissoes() ]
            
          return user
        } else return false
      } else { return false }
    }
  }