import { datadogRum } from '@datadog/browser-rum';

export default {
  install(Vue) {
    // Configurar o Datadog RUM com seu aplicativo's clientToken e applicationId
    datadogRum.init({
      applicationId: '70fbc68a-833f-437f-936a-97cdd17d4a33',
      clientToken: 'pub461d365def7fbc1b9ae08c2ee2454fa8',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'backoffice-hmg',
      env: 'production',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0', 
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    // Expondo o objeto datadogRum globalmente no Vue
    Vue.prototype.$datadogRum = datadogRum;
  },
};